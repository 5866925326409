import classNames from 'classnames';

import Icon from '../Icon/Icon';
import styles from './TitleBlock.module.scss';

const TitleBlock = ({
    title = '',
    shouldShowIcon = false,
    shouldShowUnderline = false,
}) => {
    if (!title) {
        return;
    }

    const titleClasses = classNames(styles['TitleBlock__Title'], {
        [styles['TitleBlock__Title--Underline']]: shouldShowUnderline,
    });

    return (
        <div className={styles['TitleBlock']}>
            <h2 className={titleClasses}>{title}</h2>
            {shouldShowIcon && (
                <div className={styles['TitleBlock__Icon']}>
                    <Icon icon={'arrowDown'} />
                </div>
            )}
        </div>
    );
};

TitleBlock.propTypes = {};

export default TitleBlock;
